import React from 'react'
import SpaceBetween from './space-between'
import Pagination from './blog-navigation-pagination'
import PreviousButton from './previous-button'
import NextButton from './next-button'

export default ({ current, quantity, url }) => (
  <nav>
    <SpaceBetween>
      {
        current > 2 ?
        <PreviousButton to={`${url}/${current - 1}`} title={`Blog page ${current - 1}`}/> :
        current === 2 ? 
        <PreviousButton to={`${url}`} title={`Blog page 2`}/> :
        <PreviousButton to={`${url}`} disabled title={`Blog page 1`} />
      }
      {
        current < quantity ?
        <NextButton to={`${url}/${current + 1}`} title={`Blog page ${current + 1}`}/> :
        <NextButton to={`${url}/${current + 1}`} disabled title={`Blog page ${current + 1}`}/> 
      }
    </SpaceBetween>
    <Pagination
      quantity={quantity}
      url={url}
      current={current}
    />
  </nav>
)
