import React from 'react'
import { Link } from 'gatsby'
import v4 from 'uuid'
import styled from 'styled-components'

const Ul = styled.ul`
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
`

const Li = styled.li`
  margin:  0;
`

const StyledLink = styled(Link)`
  padding: ${({ theme }) => theme.verticalRythm[9]};
  margin: 0;
  pointer-events: ${({ current }) => current === 'true' ? 'none' : 'initial'};
  text-decoration: ${({ current }) => current === 'true' ? 'none' : 'underline'};
`

export default ({ quantity, url, current }) => {
  const pages = Array.from({ length: quantity })
    .map((item, i) => (
      <Li key={v4()}>
        {
          i === 0 ?
          <StyledLink
            to={`${url}`}
            current={current === i + 1 ? 'true' : 'false'}
            title={`Blog page 1`}
          >
            1
          </StyledLink> :
          <StyledLink
            to={`${url}/${i + 1}`}
            current={current === i + 1 ? 'true' : 'false'}
            title={`Blog page ${i + 1}`}
          >
            {i + 1}
          </StyledLink>
        }
      </Li>
    )
  )

  return <Ul>{pages}</Ul>
}
