import React from 'react'
import { graphql } from 'gatsby'
import v4 from 'uuid'
import Layout from '../components/layout'
import Ast from '../components/ast'
import Seo from '../components/seo'
import PostCard from '../components/post-card'
import Navigation from '../components/blog-navigation'
import Heading from '../components/page-heading'

export default ({ data: { blog, list }, pageContext }) => {
  const { node } = blog.edges[0]
  const { title, body, path } = node
  const { currentPage, numPages } = pageContext
  const htmlAst = body.childMarkdownRemark.htmlAst

  return (
    <Layout customSeo={`true`}>
      <Seo blogPage={{
        node,
        list
      }} />
      <Heading>{title}</Heading>
      <Ast ast={htmlAst} />

      <section>
        {list.edges.map(({ node: { title, slug, date, body, path, image } }) => (
          <PostCard key={v4()} slug={slug} title={title} body={body} date={date} path={path} image={image} />
        ))}

        <Navigation current={currentPage} quantity={numPages} url={path.url} />
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query Blog($skip: Int!, $limit: Int!) {
    list: allContentfulBlogPost(sort: { fields: [date], order: DESC }, limit: $limit, skip: $skip) {
      edges {
        node {
          title
          slug
          path {
            url
            label
          }
          date(formatString: "MMMM Do YYYY")
          image {
            fluid(maxWidth: 800, quality: 100) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
          body {
            childMarkdownRemark {
              excerpt(pruneLength: 200)
              excerptAst(pruneLength: 200)
              timeToRead
            }
          }
        }
      }
    }
    blog: allContentfulBlogPage {
      edges {
        node {
          path {
            url
            label
          }
          title
          body {
            childMarkdownRemark {
              html
              htmlAst
            }
          }
        }
      }
    }
  }
`
